<template>
  <section id="conversation">

    <h2>Saindo...</h2>

  </section>
</template>

<script>
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  created() {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    useJwt.removeLogin()

    // Reset ability
    this.$ability.update(initialAbility)

    // Redirect to login page
    this.$router.push({ name: 'auth-login' })
  },
  methods: {

  },
}
</script>
